@import "base.scss";

.module {
  width: 100%;
  height: 100%;
}

.jumbotron {
  background-color: #007473;
  padding: 18px 25px;
  color: white !important;
}

.jumbotronSep {
  background-color: #007473;
  padding: 1px 0;
  color: white !important;
}

.form {
  background: #fff;
  max-height: 450px;
  overflow: auto;
  padding: 25px 40px 30px 40px;
}

.multiFieldContainer {
  padding: 10px;
  border: 1px solid #cecece;
}

.paperLoading {
  padding: 32px;
  background-color: #fff;
  min-height: 100% !important;
  min-width: 100% !important;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  padding: 32px;
  background-color: #fff;

  .modalDeclaration {
    flex-grow: 1;

    .input {
      border-radius: 4px;
      border: 1px solid #cecece;
      width: 100%;
      background-color: rgb(248, 248, 248);
      height: auto !important;
    }

    .select {
      height: 44px;
      width: 100%;
      padding: 10px 10px;
      border: 1px solid #cecece;
    }

    .flexFill {
      flex-grow: 1;
    }
  }

  .fullBtn {
    width: 100%;
  }

  .jump {
    margin-bottom: 15px;
  }

  .marginRight {
    margin-right: 10px;
  }
}

.btnExport {
  background: white;
  border: 1px solid #10d080;
  padding: 3px 12px;
  .text{
    color: #10d080;
    font-size: 15px;
  }
}
